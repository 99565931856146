import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null); // Ref for the menu

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setMenuVisible(false); // Close the menu when a button is clicked
  };

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevents immediate closing
    setMenuVisible((prev) => !prev);
  };

  return (
    <div className="navbar">
      <div className="inner-navbar">
        {/* Left Side - Logo */}
        <div className="navbar-logo-container">
          <Link to="/">
            <img
              src="/Images/logo.png"
              alt="Al Rumeli Cement Products Logo"
              className="navbar-image"
            />
          </Link>
        </div>

        {/* Middle - Heading */}
        <div className="navbar-heading">
          <h1 className="navbar-title">
            AL RUMELI CEMENT PRODUCTS
          </h1>
          <span className="subhead">A Division of Nizwa Engineering</span>
        </div>

        {/* Hamburger Menu (Visible on Mobile/Tablet) */}
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className={`line ${menuVisible ? "open" : ""}`}></div>
          <div className={`line ${menuVisible ? "open" : ""}`}></div>
          <div className={`line ${menuVisible ? "open" : ""}`}></div>
        </div>

        {/* Right Side - Buttons */}
        <div ref={menuRef} className={`navbar-buttons ${menuVisible ? "visible" : ""}`}>
          <ButtonLink to="/" label="Home" activeButton={activeButton} onClick={handleButtonClick} />
          <ButtonLink to="/services" label="Services" activeButton={activeButton} onClick={handleButtonClick} />
          <ButtonLink to="/contact" label="Contact Us" activeButton={activeButton} onClick={handleButtonClick} />
          <ButtonLink to="/about" label="About Us" activeButton={activeButton} onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  );
};

// Functional component for the buttons with Link
const ButtonLink = ({ to, label, activeButton, onClick }) => {
  return (
    <Link to={to} className="navbar-button-main">
      <button
        className={`navbar-btn ${activeButton === label ? "active" : ""}`}
        onClick={() => onClick(label)}
      >
        {label}
      </button>
    </Link>
  );
};

export default Navbar;
