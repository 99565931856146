import React from 'react';
import './Contactus.css';

function ContactUs() {
  const emailSubject = "Inquiry about Nizwa Engineering Services";
  const emailBody = "Hello, I am interested in learning more about your services. Please provide more details.";
  const whatsappMessage = "Hello, I would like to inquire about your services. Could you please provide more details?";

  return (
    <div className='conmain'>
    <div className="contact-us">
      <h2>Contact Us</h2>
      <p>Get in touch with the right department. We're here to assist you promptly and professionally.</p>

      {/* Sales Contact Card */}
      <div className="contact-card">
        <h3>Sales Department</h3>
        <p className='text3'>For inquiries related to transportation, crusher rentals, and block delivery services:</p>
        <div className="contact-detail">
          <i className="fas fa-envelope"></i>
          <span>Email:</span>
          <a
            href={`mailto:sales@nizwaeng.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
            className="email-link"
          >
           sales@alrumeli.com
          </a>
        </div>
        <div className="contact-detail">
          <i className="fab fa-whatsapp"></i>
          <span>Phone:</span>
          <a
            href={`https://wa.me/96892681010?text=${encodeURIComponent(whatsappMessage)}`}
            className="whatsapp-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            +968 9268 1010
          </a>
        </div>
      </div>

      {/* Purchasing Contact Card */}
      <div className="contact-card">
        <h3>Purchasing Department</h3>
        <p>For supplier inquiries, partnerships, and procurement of goods and services:</p>
        <div className="contact-detail">
          <i className="fas fa-envelope"></i>
          <span>Email:</span>
          <a
            href={`mailto:purchase@nizwaeng.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
            className="email-link"
          >
             purchase@alrumeli.com
          </a>
        </div>
        <div className="contact-detail">
          <i className="fab fa-whatsapp"></i>
          <span>Phone:</span>
          <a
            href={`https://wa.me/96892844415?text=${encodeURIComponent(whatsappMessage)}`}
            className="whatsapp-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            +968 9284 4415
          </a>
        </div>
      </div>

      {/* Quality Control Contact Card */}
      <div className="contact-card">
        <h3>Quality Control</h3>
        <p>For feedback and quality assurance-related concerns:</p>
        <div className="contact-detail">
          <i className="fas fa-envelope"></i>
          <span>Email:</span>
          <a
            href={`mailto:qcteam@nizwaeng.com?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
            className="email-link"
          >
           qc@alrumeli.com
          </a>
        </div>
        <div className="contact-detail">
          <i className="fab fa-whatsapp"></i>
          <span>Phone:</span>
          <a
            href={`https://wa.me/96896108883?text=${encodeURIComponent(whatsappMessage)}`}
            className="whatsapp-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            +968 96108883
          </a>
        </div>
      </div>
    </div>
    </div>
  );
}

export default ContactUs;
