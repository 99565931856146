import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Links from './components/Links.js'
function App() {
  return (
   <Router>
    <Links/>
    </Router>
  );
}
export default App;
