import React, { useState } from 'react';
import './Aboutus.css';

function AboutUs() {
  const [visibleSection, setVisibleSection] = useState(null);

  const toggleSection = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  return (
    <div className='abmain'>
    <div className="about-container">
      <h1 className="main-heading">About Al Rumeli Cement Products</h1>
      
      <div className="intro-section">
        <p>
          Welcome to <strong className="highlight">Al Rumeli</strong> Cement Products, a division of Nizwa Engineering and a key member of <strong className="highlight">Nizwa Group of Advanced Technologies & Engineering (N-Gate)</strong>. We specialize in delivering high-quality concrete solutions tailored to our clients' diverse needs.
        </p>
      </div>

      <div className="sections">
        {['Our Products', 'Our Technology', 'Our Milestones', 'Our Team', 'Mission, Vision, and Values', 'Our Slogan'].map((section, index) => (
          <div key={index} className="section-wrapper">
            <h2 className="section-title" onClick={() => toggleSection(section)}>
              {section}
              <span className="toggle-icon">{visibleSection === section ? '▼' : '▶'}</span>
            </h2>
            {visibleSection === section && (
              <div className="section-content">
                {section === 'Our Products' && (
                  <ul>
                    <li><strong>Concrete Blocks:</strong> Available in 8”, 6”, and 4” sizes.</li>
                    <li><strong>Heavy Equipment Rentals:</strong> Renting mobile & Stationary crushers, Vehicles and Heavy equipment.</li>
                  </ul>
                )}
                {section === 'Our Technology' && (
                  <ul>
                    <li><strong>BESSER BH4 Block Machine:</strong> This American-engineered system produces high-strength blocks through a combination of pressure and controlled vibration.</li>
                    <li><strong>Curing System:</strong> Our Coil Duct Heating System maintains optimal moisture and humidity levels during curing and ensurring the consistent block durability.</li>
                    <li><strong>Computerized Batching:</strong> Guarantees precision in ingredient mixing, resulting in uniform quality across all products.</li>
                  </ul>
                )}
                {section === 'Our Milestones' && (
                  
                  <ul>
                    <p className='para'>Our commitment to quality has enabled us to contribute to several prestigious projects, including:</p>
                    <li>Nizwa Grand Mall</li>
                    <li>Hotel Intercity</li>
                    <li>Defense Project in Shafa</li>
                    <br></br>
                    <p className='para'>These collaborations highlight our capability to meet the stringent demands of diverse construction endeavors.</p>
                  </ul>
                )}
                {section === 'Our Team' && (
                  <ul>
                    <li><strong>Managing Director:</strong> A seasoned civil engineer providing strategic leadership.</li>
                    <li><strong>Plant Engineer:</strong> Ensures efficient operation of our state-of-the-art machinery.</li>
                    <li><strong>Professional Supervisors:</strong> Oversee quality control and production processes.</li>
                    <li><strong>Skilled Employees:</strong> Bring experience and dedication to every project.</li>
                  </ul>
                )}
                {section === 'Mission, Vision, and Values' && (
                  <ul>
                    <li><strong>Mission:</strong> Deliver superior concrete products that empower construction projects.</li>
                    <li><strong>Vision:</strong> To be Oman’s leading provider of concrete solutions.</li>
                    <li><strong>Core Values:</strong> Customer-Centricity, Quality, Innovation, and Teamwork.</li>
                  </ul>
                )}
                {section === 'Our Slogan' && (
                  <h3 className="slogan">"Al Rumeli Cement Products – Redefining Concrete Precision."</h3>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default AboutUs;
