import React, { useState, useRef, useEffect } from 'react';
import './home.css';
import { motion } from 'framer-motion';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';


// Import Crusher Services Images
import img from '../../Assets/Images/Stationary_Impactor.png';
import img4 from '../../Assets/Images/Stationary_Jaw.png';
import img5 from '../../Assets/Images/Mobile_Jaw.png';
import img6 from '../../Assets/Images/Mobile_Impactor.png';

// Import Heavy Equipment Rentals Images
import img7 from '../../Assets/Images/345gc.png';
import img8 from '../../Assets/Images/Dozer.png';
import imgDump from '../../Assets/Images/dumper745.png';
import imgExc from '../../Assets/Images/966_WHeelLoader.png';

// Import Transportation Services Images
import imgTipper from '../../Assets/Images/Tipper.png';
import img3Axle from '../../Assets/Images/6564.png';
import imgKingLowbed from '../../Assets/Images/KingLowbed.png';
import imgBulker from '../../Assets/Images/8835.png';

// Import all product images
import img1 from '../../Assets/Images/8H.png';
import img2 from '../../Assets/Images/6H.png';
import img3 from '../../Assets/Images/4H.png';
import img4p from '../../Assets/Images/8S.png'; // New product image
import img5p from '../../Assets/Images/6S.png'; // New product image
import img6p from '../../Assets/Images/4S.png'; // New product image
const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = { lat: 22.9333, lng: 57.5333 }; // Nizwa, Oman

function Home() {
  const blockDetails = [
    {
      id: 1,
      name: '8 Inch Hollow Block',
      dimensions: '390mm x 190mm x 190mm',
      weight: '20.100 kg',
      bundleSize: '75 pcs',
      imagePath: '/Images/8H.png',
    },
    {
      id: 2,
      name: '6 Inch Hollow Block',
      dimensions: '390mm x 190mm x 140mm',
      weight: '16.500kg',
      bundleSize: '90 pcs',
      imagePath: '/Images/6H.png',
    },
    {
      id: 3,
      name: '4 Inch Hollow Block',
      dimensions: '390mm x 190mm x 90mm',
      weight: '10.050 kg',
      bundleSize: '150 pcs',
      imagePath: '/Images/4H.png',
    },
    {
      id: 4,
      name: '8 Inch Solid Block',
      dimensions: '390mm x 190mm x 190mm',
      weight: '27.700 kg',
      bundleSize: '75 pcs',
      imagePath: '/Images/8S.png',
    },
    {
      id: 5,
      name: '6 Inch Solid Block',
      dimensions: '390mm x 190mm x 140mm',
      weight: '21.300 kg',
      bundleSize: '90 pcs',
      imagePath: '/Images/6S.png',
    },
    {
      id: 3,
      name: '4 Inch Solid Block',
      dimensions: '390mm x 190mm x 90mm',
      weight: '13.800 kg',
      bundleSize: '150 pcs',
      imagePath: '/Images/4S.png',
    },];
  const [activeTab, setActiveTab] = useState('products');
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMapPopup, setShowMapPopup] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBOinZQxFkBmDcmB5s_0A8gONDN4pEMbas', // Replace with your actual API key
  });
  const handleCheckboxChange = (blockName, isChecked) => {
    if (isChecked) {
      setOrderItems([...orderItems, { blockType: blockName, quantity: 0 }]);
    } else {
      setOrderItems(orderItems.filter((item) => item.blockType !== blockName));
    }
  };

  const handleQuantityChange = (blockName, quantity) => {
    const updatedOrderItems = orderItems.map((item) =>
      item.blockType === blockName ? { ...item, quantity: parseInt(quantity) || 0 } : item
    );
    setOrderItems(updatedOrderItems);
  };

  const handleMapClick = (event) => {
    const { lat, lng } = event.latLng.toJSON();
    setSelectedLocation({ lat, lng });
  };

  const handleConfirmLocation = () => {
    setShowMapPopup(false);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    // Generate a formatted quote request message
    let emailBody = "Dear Nizwa Engineering,\n\nI would like to request a quote for the following concrete products:\n\n";
    orderItems.forEach((item) => {
      if (item.quantity > 0) {
        emailBody += `${item.blockType} - Quantity: ${item.quantity} ${item.blockType === '4 Inch Solid Block' ? 'pcs' : 'bundles'}\n`;
      }
    });

    // Add location information if available
    if (selectedLocation) {
      emailBody += `\nDelivery Location: Latitude ${selectedLocation.lat}, Longitude ${selectedLocation.lng}`;
    }

    emailBody += `\nName: ${name}\nEmail: ${email}\nMessage: ${message}\n\nSincerely,`;

    // Open the user's email client with the pre-filled subject and body
    window.open(`mailto:sales@nizwaeng.com?subject=Quote Request&body=${encodeURIComponent(emailBody)}`);
  };

  const handleWhatsAppSubmit = (e) => {
    e.preventDefault();

    // Generate a formatted quote request message
    let whatsappMessage = "Dear Nizwa Engineering,%0A%0AI would like to request a quote for the following concrete products:%0A%0A";
    orderItems.forEach((item) => {
      if (item.quantity > 0) {
        whatsappMessage += `${item.blockType} - Quantity: ${item.quantity} ${item.blockType === '4 Inch Solid Block' ? 'pcs' : 'bundles'}%0A`;
      }
    });

    // Add location information if available
    if (selectedLocation) {
      whatsappMessage += `%0ADelivery Location: Latitude ${selectedLocation.lat}, Longitude ${selectedLocation.lng}`;
    }

    whatsappMessage += `%0AName: ${name}%0AEmail: ${email}%0AMessage: ${message}%0A%0ASincerely,`;

    // Open WhatsApp with the pre-filled message
    window.open(`https://wa.me/+96892681010?text=${whatsappMessage}`);
  };


  const products = [ // Array of product objects
    { image: img1, caption: '8" Hollow (390 mm x 190 mm x 190 mm)' },
    { image: img2, caption: '6" Hollow (390 mm x 190 mm x 140 mm)' },
    { image: img3, caption: '4" Hollow (390 mm x 190 mm x 90 mm)' },
    { image: img4p, caption: '8" Solid (390 mm x 190 mm x 190 mm)' }, // New product
    { image: img5p, caption: '6" Solid (390 mm x 190 mm x 140 mm)' }, // New product
    { image: img6p, caption: '4" Solid (390 mm x 190 mm x 90 mm)' }, // New product
  ];

  const [productStartIndex, setProductStartIndex] = useState(0);
  const visibleProducts = 3; // Number of products visible at a time
  const productContainerRef = useRef(null);

  const handlePrevProducts = () => {
    setProductStartIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const handleNextProducts = () => {
    setProductStartIndex((prevIndex) =>
      prevIndex >= products.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Function to get the visible products with looping logic
  const getVisibleProducts = () => {
    const visible = [];
    for (let i = 0; i < visibleProducts; i++) {
      const index = (productStartIndex + i) % products.length;
      visible.push(products[index]);
    }
    return visible;
  };

  useEffect(() => {
    // Optional: Smooth scrolling effect
    if (productContainerRef.current) {
      const productWidth = productContainerRef.current.offsetWidth / visibleProducts;
      productContainerRef.current.scrollTo({
        left: productStartIndex * productWidth,
        behavior: 'smooth',
      });
    }
  }, [productStartIndex]);

  // Data for Head Office Services
  const headOfficeServices = [
    {
      title: 'Crushing & Screening',
      images: [img, img4, img5, img6],
    },
    {
      title: 'Heavy Equipment Rentals',
      images: [img7, img8, imgDump, imgExc],
    },
    {
      title: 'Transport & Logistics Solutions',
      images: [imgTipper, img3Axle, imgKingLowbed, imgBulker],
    },
  ];

  return (
    <div className="home-main">
      <section className="hero-section">
        <div className="hero-overlay">
          <motion.h1
            className="hero-title"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            High-Quality Cement Products & Reliable Logistics
          </motion.h1>

          <motion.p
            className="hero-subtitle"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            Providing top-grade blocks and transportation solutions for your projects.
          </motion.p>
        </div>
      </section>
      <div className="button-container">
        <button
          className={`tab-button ${activeTab === 'products' ? 'active' : ''}`}
          onClick={() => setActiveTab('products')}
        >
          Our Products
        </button>
        <button
          className={`tab-button ${activeTab === 'services' ? 'active' : ''}`}
          onClick={() => setActiveTab('services')}
        >
          Head Office Services
        </button>
      </div>

      <div className="tab-content-container">
        {activeTab === 'products' && (
          <div className="products-container">
            <button
              className="arrow-button prev-arrow"
              onClick={handlePrevProducts}
            >
              <span className="arrow-icon">&#10094;</span>
            </button>

            <div className="product-carousel" ref={productContainerRef}>
              {getVisibleProducts().map((product, index) => (
                <div key={index} className="product-item">
                  <img src={product.image} alt={product.caption} />
                  <div className="product-caption">{product.caption}</div>
                </div>
              ))}
            </div>

            <button
              className="arrow-button next-arrow"
              onClick={handleNextProducts}
            >
              <span className="arrow-icon">&#10095;</span>
            </button>
          </div>
        )}

        {activeTab === 'services' && (
          <div className="services-container">
            {headOfficeServices.map((service, index) => (
              <div key={index} className="service-item">
                <h3 className="service-title">{service.title}</h3>
                <div className="service-images">
                  {service.images.map((image, idx) => (
                    <div key={idx} className="service-image-item">
                      <img src={image} alt={`Service ${idx + 1}`} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Request for Quote Button */}
      <button className="request-quote-button" onClick={() => setShowQuoteForm(true)}>
        Request for Quote
      </button>

      {/* Quote Request Form Popup */}
      {showQuoteForm && (
        <div className="quote-form-popup">
          <div className="quote-form-container">
            {/* Close button on the right */}
            <button className="close-button" onClick={() => setShowQuoteForm(false)}>
              &times;
            </button>

            {/* Heading for the Quote Form */}
            <div className="quote-form-heading">
              <h3>Request a Quote</h3>
              <p>Please select the types and quantities.</p>
            </div>

            <form>
              <div className="checkbox-grid">
                {blockDetails.map((block) => (
                  <div key={block.id} className="block-checkbox">
                    <input
                      type="checkbox"
                      id={block.id}
                      value={block.name}
                      onChange={(e) => handleCheckboxChange(block.name, e.target.checked)}
                    />
                    <label htmlFor={block.id}>{block.name}</label>
                    {orderItems.find((item) => item.blockType === block.name) ? (
                       <input
                        type="number"
                        min="0"
                        placeholder="Quantity"
                        value={orderItems.find((item) => item.blockType === block.name).quantity}
                        onChange={(e) => handleQuantityChange(block.name, e.target.value)}
                      />
                    ) : null}
                  </div>
                ))}
              </div>

              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
              />

              {/* Location Selection */}
              <button type="button" onClick={() => setShowMapPopup(true)} className="select-location-button">
                Select Delivery Location
              </button>

              {selectedLocation && (
                <p className="selected-location">
                  Selected Location: Latitude {selectedLocation.lat}, Longitude {selectedLocation.lng}
                </p>
              )}

              {/* Email and WhatsApp Buttons */}
              <div className="submit-buttons">
                <button type="button" className="email-button" onClick={handleEmailSubmit}>
                  <i className="fas fa-envelope"></i> Email
                </button>
                <button type="button" className="whatsapp-button" onClick={handleWhatsAppSubmit}>
                  <i className="fab fa-whatsapp"></i> WhatsApp
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Map Popup */}
{showMapPopup && isLoaded && (
  <div className="map-popup">
    {/* Close Button */}
    <button className="map-close-button" onClick={() => setShowMapPopup(false)}>
      &times;
    </button>

    {/* Google Map */}
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={defaultCenter}
        zoom={12}
        onClick={handleMapClick}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
      </GoogleMap>
    </div>

    {/* Map Buttons */}
    <div className="map-buttons">
      <button onClick={handleConfirmLocation} disabled={!selectedLocation}>
        Confirm Location
      </button>
      <button onClick={() => setShowMapPopup(false)}>Cancel</button>
    </div>
  </div>
)}


      {/* Thank You Message */}
      {showThankYou && (
        <div className="thank-you-popup">
          <div className="thank-you-message">
            <h2>Thank You!</h2>
            <p>Your quote request has been received.</p>
            <p>You will receive a response from the boat "Rumeli" soon.</p>
            <button onClick={() => setShowThankYou(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;