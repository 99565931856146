import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from './Home/home';
import Navbar from './Navbar/navbar';
import Services from './Services/Services';
import Aboutus from './Aboutus/About Us.js';
import Footer from './Footer/Footer.js';
import Contactus from './Contactus/Contact Us.js';
function Links() {
  return (
    <div>
     <Navbar/>
     <Routes>
      <Route>
       <Route path="/" element={<Home />} />
       <Route path="/services" element={<Services />} />
       <Route path="/contact" element={<Contactus/>} />
       <Route path="/about" element={<Aboutus/>} />
      </Route>
     </Routes>
     <Footer/>
    </div>
  )
}

export default Links
